//@author: travis

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25BulkMap } from "../s25.bulk.edit.util";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-bulk-edit-text")
@Component({
    selector: "s25-ng-bulk-edit-text",
    template: `
        @if (this.init) {
            <div class="c-margin-top--single">
                <span class="ngBold">{{ this.modelBean.title }}</span>
                <s25-ng-editable-text
                    [min]="this.modelBean.minLength"
                    [max]="this.modelBean.maxLength"
                    [allowEmpty]="true"
                    [alwaysEditing]="true"
                    [(val)]="this.val"
                ></s25-ng-editable-text>
                <s25-ng-bulk-edit-save
                    [submitFn]="saveFn"
                    [buttonText]="'Update ' + this.modelBean.title"
                ></s25-ng-bulk-edit-save>
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkEditTextComponent implements OnInit {
    @Input() itemIds: number[];
    @Input() itemTypeId: number = 2;
    @Input() itemType: string; //see S25BulkMap for options
    val: string = "";
    init: boolean;
    modelBean: any;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    ngOnInit(): void {
        this.modelBean = S25BulkMap[this.itemTypeId][this.itemType];

        this.init = true;
        this.cd.detectChanges();
    }

    get saveFn() {
        return this.save.bind(this);
    }

    save() {
        return this.modelBean.service(this.itemIds, this.val);
    }
}
