//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25BulkEditSaveApi } from "../save/s25.bulk.edit.save.api";
import { S25Const } from "../../../util/s25-const";
import { RelationshipService } from "../../../services/relationship.service";
import { SpaceService } from "../../../services/space.service";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-bulk-edit-relationship")
@Component({
    selector: "s25-ng-bulk-edit-relationship",
    template: `
        @if (this.init) {
            <div>
                <label class="c-margin-top--single ngBold" [style.margin-bottom]="0"> Relationships </label>
                <s25-toggle-button
                    (modelValueChange)="this.onToggleChange($event)"
                    [modelValue]="this.remove"
                    [trueLabel]="trueLabel"
                    [falseLabel]="falseLabel"
                    class="ngBlock c-margin-bottom--single"
                ></s25-toggle-button>
                <s25-generic-dropdown
                    [items]="this.items"
                    [placeholder]="'Select a relationship'"
                    [(chosen)]="this.chosen"
                    (chosenChange)="onChosen()"
                    [searchEnabled]="false"
                ></s25-generic-dropdown>
                <div class="c-margin-top--single">
                    <s25-ng-multiselect-search-criteria
                        [(modelBean)]="this.modelBean"
                        honorMatching="false"
                        [type]="this.type"
                    ></s25-ng-multiselect-search-criteria>
                </div>
                <s25-ng-bulk-edit-save
                    [submitFn]="updateFn"
                    [buttonText]="'Update Relationships'"
                ></s25-ng-bulk-edit-save>
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkEditRelationshipComponent implements OnInit {
    @Input() itemTypeId: number;
    @Input() itemIds: any = undefined;
    @Input() falseLabel: string = "Add";
    @Input() trueLabel: string = "Remove";
    @Input() submitFn: Function; //Save function that returns a promise.
    init: boolean;
    remove: boolean = false;
    modelBean: any = { showResult: true, showMatching: false, filterFavorites: false, domainFilter: false };
    chosen: any;
    items: any;
    type: any;

    constructor(
        private zone: NgZone,
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    ngOnInit() {
        console.log({ itemIds: this.itemIds });
        this.items = S25Const.relationships[this.itemTypeId];
        this.itemTypeId === 4 ? (this.type = "locations") : (this.type = "resources");
        this.init = true;
        this.cd.detectChanges();
    }

    onToggleChange(event: any) {
        this.remove = event;
        this.cd.detectChanges();
    }

    onChosen() {
        this.chosen.itemId;
        S25BulkEditSaveApi.reset(this.elementRef.nativeElement);
    }

    get updateFn() {
        return this.update.bind(this);
    }

    update() {
        if (!this.chosen) {
            alert("Please select a relationship.");
        } else if (this.modelBean.addedItems.length === 0) {
            alert("Please select " + this.type + ".");
        } else {
            if (this.itemTypeId === 4) {
                let nodeName = S25Const.relationships[this.itemTypeId][this.chosen.itemId - 1].nodeName;
                let payload: any;

                if (this.remove) {
                    payload = {
                        idList: this.itemIds,
                        remove: {
                            relationships: {
                                [nodeName]: this.modelBean.addedItems.map(function (a: any) {
                                    return { spaceId: a.itemId };
                                }),
                            },
                        },
                    };
                } else {
                    payload = {
                        idList: this.itemIds,
                        relationships: {
                            [nodeName]: this.modelBean.addedItems.map(function (a: any) {
                                return { spaceId: a.itemId };
                            }),
                        },
                    };
                }

                return SpaceService.microUpdate(payload);
            } else {
                //resource doesn't have micro service ready yet
                if (this.remove) {
                    return RelationshipService.deleteRelationships(
                        this.itemIds,
                        this.chosen.itemId,
                        this.modelBean.addedItems,
                        this.itemTypeId,
                    );
                } else {
                    return RelationshipService.updateRelationships(
                        this.itemIds,
                        this.chosen.itemId,
                        this.modelBean.addedItems,
                        this.itemTypeId,
                    );
                }
            }
        }
    } // END update
}
