//@author: travis

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { DropDownItem } from "../../../pojo/DropDownItem";
import { NotificationPolicyService } from "../../../services/notification.policy.service";
import { S25Const } from "../../../util/s25-const";
import { S25NotificationPolicyComponent } from "../../s25-notification-policy/s25.notification.policy.component";
import { S25BulkEditSaveApi } from "../save/s25.bulk.edit.save.api";
import { PayloadI } from "../../../pojo/EventTypeBulkEditPayloadI";
import { jSith } from "../../../util/jquery-replacement";
import { EventService } from "../../../services/event.service";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { NotificaionPolicyApi } from "../../s25-notification-policy/s25.notification.policy.api";

@TypeManagerDecorator("s25-ng-bulk-edit-notify-policy")
@Component({
    selector: "s25-ng-bulk-edit-notify-policy",
    template: `@if (this.init) {
        <div>
            <span class="c-margin-top--half c-margin-right--half ngInlineBlock ngBold">Update Notify Type Only</span>
            <s25-toggle-button
                (modelValueChange)="this.onNotifyTypeChange($event)"
                [modelValue]="this.notifyTypeOnly"
                class="ngBlock c-margin-bottom--single"
            ></s25-toggle-button>
            @if (this.notifyTypeOnly) {
                <div>
                    <s25-generic-dropdown
                        [items]="this.notifyTypes"
                        [searchEnabled]="false"
                        (chosenChange)="setNotifyType($event, i)"
                    ></s25-generic-dropdown>
                </div>
            }
            @if (!this.notifyTypeOnly) {
                <div>
                    <span class="c-margin-right--half ngInlineBlock ngBold">Update Notification Policy By</span>
                    <s25-toggle-button
                        (modelValueChange)="this.onTypeChange($event)"
                        [modelValue]="this.contactsOnly"
                        [trueLabel]="'Template'"
                        [falseLabel]="'Contacts'"
                        class="ngBlock c-margin-bottom--single"
                    ></s25-toggle-button>
                    @if (this.contactsOnly) {
                        <div>
                            <span class="c-margin-right--half ngInlineBlock ngBold">Add/Remove Contacts</span>
                            <p class="ngFinePrint">
                                Add or remove contacts from existing notification policy without changing timeline or
                                overall approval type.
                            </p>
                            <s25-toggle-button
                                (modelValueChange)="this.onContactToggleChange($event)"
                                [modelValue]="this.addRemoveContact"
                                [trueLabel]="'Remove'"
                                [falseLabel]="'Add'"
                            ></s25-toggle-button>
                            @if (this.contactToggle == "Add") {
                                <div>
                                    <s25-ng-notification-policy-contacts
                                        [(model)]="this.contList"
                                        class="notification-policy--wrapper"
                                    ></s25-ng-notification-policy-contacts>
                                </div>
                            }
                            @if (this.contactToggle == "Remove") {
                                <div>
                                    <s25-ng-multiselect-search-criteria
                                        [type]="'contacts'"
                                        [(modelBean)]="this.contList"
                                        honorMatching="true"
                                        class="removeContacts"
                                    ></s25-ng-multiselect-search-criteria>
                                </div>
                            }
                        </div>
                    }
                    @if (!this.contactsOnly) {
                        <div>
                            @if (this.contactsOnly !== null && this.contactsOnly !== undefined) {
                                <div>
                                    <span class="ngBold">Select Template</span>
                                    @if (this.itemTypeId !== 7) {
                                        <s25-ng-dropdown-search-criteria
                                            [type]="this.type"
                                            [(chosen)]="this.chosen"
                                            (chosenChange)="chosenTemplate()"
                                        ></s25-ng-dropdown-search-criteria>
                                    }
                                    @if (this.itemTypeId === 7) {
                                        <s25-generic-dropdown
                                            [items]="this.chosenModels"
                                            [(chosen)]="this.chosen"
                                            [searchEnabled]="true"
                                            (chosenChange)="chosenTemplate()"
                                        ></s25-generic-dropdown>
                                    }
                                    <!--<s25-organization-dropdown [(chosen)]="this.chosen" (chosenChange)="chosenTemplate()"></s25-organization-dropdown>-->
                                </div>
                            }
                            @if (this.itemTypeId !== 7) {
                                <s25-ng-notification-policy
                                    [itemId]="this.chosen?.itemId"
                                    [bulkItemIds]="this.itemIds"
                                    [itemTypeId]="this.itemTypeId"
                                    [canEdit]="true"
                                    [inModal]="true"
                                    [isBulk]="true"
                                    [placeholderText]="this.placeholderText"
                                    (valueChange)="onChangePolicy($event)"
                                ></s25-ng-notification-policy>
                            }
                            @if (this.itemTypeId === 7) {
                                <s25-ng-notification-policy
                                    [itemId]="this.chosen?.itemId"
                                    [bulkItemIds]="this.itemIds"
                                    hasSaveButton="false"
                                    [itemTypeId]="this.itemTypeId"
                                    [canEdit]="true"
                                    [inModal]="true"
                                    [isBulk]="true"
                                    [placeholderText]="this.placeholderText"
                                    (valueChange)="onChangePolicy($event)"
                                ></s25-ng-notification-policy>
                            }
                        </div>
                    }
                </div>
            }
            <s25-ng-bulk-edit-save [submitFn]="updateFn" [buttonText]="buttonText"></s25-ng-bulk-edit-save>
            @if (this.successMsg && this.successMsg !== "") {
                <div
                    class="ngGreen ngBold cn-alert cn-alert--success c-margin-bottom--single c-margin-top--single"
                    role="alert"
                >
                    <div class="cn-alert__icon cn-icon" name="alert--info">
                        <svg class="cn-svg-icon" role="img">
                            <title>Success Alert</title>
                            <use
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#check"
                            ></use>
                        </svg>
                    </div>
                    <div class="cn-alert__label">
                        <span>{{ this.successMsg }}</span>
                    </div>
                </div>
            }
        </div>
    }`,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkEditNotifyPolicyComponent implements OnInit {
    @Input() itemTypeId: number = undefined;
    @Input() itemIds: number[];
    // @Input() updateType?: 'template'|'contact'|'new';
    @Input() contactsOnly?: boolean;
    @Input() chosenModels?: any = [];
    @Input() itemTypeName?: any;

    @ViewChild(S25NotificationPolicyComponent) notifyChild: S25NotificationPolicyComponent;

    type: string;
    updating = false;
    message: string;
    chosen: DropDownItem = null;
    items: DropDownItem[];
    init = false;
    contList: any = { selectedItems: [], showResult: false, showMatching: false };
    contactToggle: string;
    policy: any;
    buttonText: string = "Update Notification Policy";
    notificationPolicy: any = {};
    msg: string = "";
    policyChanged = false;
    notifyTypeOnly: boolean = false;

    notifyTypes = [
        { itemName: "Notify only", itemId: 1 },
        { itemName: "Approval required", itemId: 2 },
    ];

    selectedNotifyType: number;

    chunkSize = 500;
    concurrentMax = 5;
    arrIndex = 0;
    chunkCount = 0;
    chunkIdx = 0;
    successMsg: any;

    placeholderText: string = "Select an Approval Type";

    constructor(
        private zone: NgZone,
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    ngOnInit(): void {
        this.chunkCount = Math.ceil(this.itemIds.length / this.chunkSize);
        this.type =
            S25Const.itemId2Display[this.itemTypeId] &&
            S25Const.itemId2Display[this.itemTypeId].plural &&
            S25Const.itemId2Display[this.itemTypeId].plural.toLowerCase();
        this.init = true;
        this.cd.detectChanges();
    }

    setNotifyType(e: any) {
        this.selectedNotifyType = e.itemId;
        // console.log({ notifyType: e });
    }

    onNotifyTypeChange(event: any) {
        this.notifyTypeOnly = event;
        //console.log({ onNotifyTypeChange: event, notifyTypeOnly: this.notifyTypeOnly });
        this.reset();
        this.cd.detectChanges();
    }

    chosenTemplate() {
        this.placeholderText = "No Notification Policy";
        NotificaionPolicyApi.refresh(this.elementRef.nativeElement);
        S25BulkEditSaveApi.reset(this.elementRef.nativeElement);
        this.cd.detectChanges();
    }

    onTypeChange(event: any) {
        this.reset();
        // this.updateType = event ? "template" : "contact";
        this.contactsOnly = !event;

        this.cd.detectChanges();
    }

    onContactToggleChange(event: any) {
        this.reset();
        this.contactToggle = event ? "Remove" : "Add";
        this.cd.detectChanges();
    }

    reset() {
        this.chosen = null;
        this.contactToggle = null;
        this.message = null;
        this.contList = { contacts: [], showResult: true, showMatching: false };
        S25BulkEditSaveApi.reset(this.elementRef.nativeElement);
    }

    get updateFn() {
        return this.update.bind(this);
    }

    update() {
        this.successMsg = "";
        this.cd.detectChanges();
        if (this.notifyTypeOnly) {
            return NotificationPolicyService.updateNotifyTypeOnly(
                this.itemTypeId,
                this.itemIds,
                1,
                this.selectedNotifyType,
            );
        } else {
            if (
                this.notificationPolicy &&
                Object.keys(this.notificationPolicy).length === 0 &&
                this.notifyChild &&
                this.notifyChild.notificationPolicy &&
                !this.notifyChild.notificationPolicy.approvalType &&
                this.placeholderText !== "No Notification Policy"
            ) {
                this.msg = "Please select an approval type.";
                alert(this.msg);
                return false;
            } else {
                if (this.itemTypeId === 7) {
                    return this.saveEventTypePolicy();
                } else {
                    if (
                        this.notificationPolicy &&
                        this.notificationPolicy.approvalType &&
                        this.notificationPolicy.approvalType.itemId === 0
                    ) {
                        return this.delete();
                    } else {
                        if (this.contactsOnly) {
                            if (this.contactsOnly && this.contactToggle === null) {
                                this.msg = "Please toggle to add or remove contacts.";
                                alert(this.msg);
                                return false;
                            } else if (
                                this.contactsOnly &&
                                ((this.contList.contacts &&
                                    this.contList.contacts.length === 0 &&
                                    this.contactToggle === null) ||
                                    (this.contList.selectedItems && this.contList.selectedItems.length === 0))
                            ) {
                                this.msg = "Please select a contact.";
                                alert(this.msg);
                                return false;
                            } else {
                                return this.saveContacts();
                            }
                        } else {
                            if (
                                (this.notificationPolicy &&
                                    Object.keys(this.notificationPolicy).length === 0 &&
                                    this.placeholderText === "No Notification Policy") ||
                                !this.notificationPolicy.approvalType
                            ) {
                                return this.delete();
                            } else {
                                return this.savePolicy();
                            }
                        }
                    }
                }
            }
        }
    }

    saveContacts() {
        let approvalTypeId: number;
        let respondWithin: string;
        let deleteAllContacts: boolean;

        let addContacts: any;
        let removeContacts: any;
        if (this.contactToggle == "Remove") {
            removeContacts = this.contList.selectedItems;
        } else {
            addContacts = this.contList.selectedItems;
        }

        return NotificationPolicyService.setBulk(
            this.itemTypeId,
            this.itemIds,
            approvalTypeId,
            respondWithin,
            addContacts,
            removeContacts,
            deleteAllContacts,
        );
    }

    savePolicy() {
        return this.notifyChild.save();
    }

    saveEventTypePolicy() {
        if (!this.contactsOnly && !this.policyChanged) {
            //when toogle is template, get template, but template policy did not make any changes
            this.notificationPolicy = this.notifyChild.notificationPolicy;
        }

        if (this.validateForEventType()) {
            let payload: PayloadI = undefined;
            let contact: any = [];

            if (this.contactsOnly) {
                let addContacts: any;
                let removeContacts: any;
                if (this.contactToggle == "Remove") {
                    removeContacts = this.contList.selectedItems;
                    jSith.forEach(removeContacts, (key, i) => {
                        contact.push({ contId: i.itemId });
                    });
                    payload = { idList: this.itemIds, notificationPolicy: { remove: { contacts: contact } } };
                } else {
                    addContacts = this.contList.selectedItems;
                    jSith.forEach(addContacts, (key, i) => {
                        contact.push({
                            contId: i.itemId,
                            notifyType: i.notifyType.itemId,
                            notifyTypeName: i.notifyType.itemId === 1 ? "FYI" : "approval",
                        });
                    });
                    payload = { idList: this.itemIds, notificationPolicy: { contacts: contact } };
                }
            } else if (
                (this.notificationPolicy.approvalType && this.notificationPolicy.approvalType.itemId === 0) ||
                Object.entries(this.notificationPolicy).length > 0 === false
            ) {
                payload = { idList: this.itemIds, remove: { notificationPolicy: "" } };
            } else if (Object.entries(this.notificationPolicy).length > 0 !== false) {
                let days: number = this.notificationPolicy.days || 0;
                let hours: number = this.notificationPolicy.hours || 0;
                let minutes: number = this.notificationPolicy.minutes || 0;
                let respondWithin = "P" + days + "DT" + hours + "H" + minutes + "M";
                jSith.forEach(this.notificationPolicy.contacts, (key, i) => {
                    if (i.status !== "del") {
                        contact.push({
                            contId: i.itemId,
                            notifyType: i.notifyType.itemId,
                            notifyTypeName: i.notifyType.itemId === 1 ? "FYI" : "approval",
                        });
                    }
                });
                payload = {
                    idList: this.itemIds,
                    notificationPolicy: {
                        approvalType: this.notificationPolicy.approvalType.itemId,
                        approvalTypeName: this.notificationPolicy.approvalType.itemId === 1 ? "One" : "All",
                        respondWithin: respondWithin,
                        contacts: contact,
                    },
                };
            }
            return EventService.updateBulkEditEventType(payload);
        }
    }

    onChangePolicy(e: any) {
        this.notificationPolicy = e;
        this.policyChanged = true;
    }

    onLoad() {
        this.cd.detectChanges();
    }

    validateForEventType() {
        this.msg = "";
        if (
            this.contactsOnly &&
            ((this.contList.contacts && this.contList.contacts.length === 0 && this.contactToggle === null) ||
                (this.contList.selectedItems && this.contList.selectedItems.length === 0))
        ) {
            this.msg = "Please select a contact.";
            alert(this.msg);
            return false;
        }

        if (this.notificationPolicy.contacts && this.notificationPolicy.contacts.length === 0) {
            this.msg = "Please add a contact to this policy.";
            alert(this.msg);
            return false;
        }

        if (this.contactToggle === "Add") {
            let find = this.contList.selectedItems.find((c: any) => {
                return c && !c.notifyType;
            });

            if (find) {
                this.msg = "All contacts require a notify type to be selected.";
                alert(this.msg);
                return false;
            }
        }

        return true;
    }

    delete() {
        let batchHandler: any = (chunkIdx: any) => {
            this.chunkIdx++;
            let start = chunkIdx * this.chunkSize; //0,         chunkSize,     2 * chunkSize...
            let end = start + this.chunkSize; //     chunkSize, 2 * chunkSize, 3 * chunkSize...
            let chunk = this.itemIds.slice(start, end);
            return NotificationPolicyService.deleteBulk(this.itemTypeId, chunk);
            ///////////////////////////////
        };

        S25Util.batch(batchHandler, this.concurrentMax, this.chunkCount).then(
            (resp) => {
                let checkSuccess = resp.find((i: any) => i.root === "");
                checkSuccess ? (this.successMsg = "Success!") : "";
                this.cd.detectChanges();
            },
            (error) => {
                this.done(error);
            },
        );
    }

    done(error?: any) {
        error && S25Util.showError(error);
    }
}
